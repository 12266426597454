
import get from "lodash/get"
import has from "lodash/has"
import breakpoints from "@/assets/style/_breakpoints.scss"

export default {
  data: () => ({
    speakers: [],
  }),

  async fetch () {
    await this.fetchSpeakers()
  },

  computed: {
    swiperOpts () {
      const total = this.speakers.length
      return {
        spaceBetween: 32,
        freeMode: false,
        slidesPerView: total > 1 ? 1.2 : 1,
        centeredSlides: true,
        centeredSlidesBounds: true,
        breakpoints: {
          [breakpoints.xs]: {
            slidesPerView: 2,
          },
          [breakpoints.lg]: {
            spaceBetween: 100,
            slidesPerView: 3,
          },
        },
      }
    },
  },

  methods: {
    get,

    async fetchSpeakers () {
      const [res, err] = await this.$api.person.getSpeakers({
        params: { "filter[front]": true },
      })
      if (err) console.error(err)
      else this.speakers = this.$api.helpers.getList(res)
    },

    image (item) {
      const frontImage = "frontPagePhoto.url"
      const image = "photo.url"
      if (has(item, frontImage)) return get(item, frontImage, "")
      return get(item, image, "")
    },

    openModal (speaker) {
      this.$nuxt.$emit("open-speaker", speaker, false)
    },
  },
}
